// css for small card
import "./SmallCard.css";
// mui components
import { Card, Stack, Typography } from "@mui/material";

const SmallCard = ({ icon, count, textContent, myColor, BgColor }) => {
  return (
    <Card
      sx={{
        padding: 2,
        borderRadius: 2,
        background: `${BgColor}`,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderLeft: `4px solid ${myColor}`, // Color accent on the left
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Left Side: Count and Text */}
        <Stack direction="column">
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: `${myColor}`,
            }}
          >
            {count}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: myColor,
              fontWeight: "500",
            }}
          >
            {textContent}
          </Typography>
        </Stack>

        {/* Right Side: Icon */}
        <Stack
          sx={{
            backgroundColor: `${myColor}33`, // Lightened version of color for icon background
            borderRadius: "50%",
            height: "48px",
            width: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: myColor,
            fontSize: "24px",
          }}
        >
          {icon}
        </Stack>
      </Stack>
    </Card>
  );
};

export default SmallCard;
