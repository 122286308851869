import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import * as XLSX from "xlsx";
import { retry } from "@reduxjs/toolkit/query";

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  fullscreen = false,
  maxWidth = "xs",
  MyBgColor,
  PDFData,
  ExcelData,
  ViewDATA,
  Discounted_Amount,
}) => {
  useEffect(() => {
    if (ViewDATA) {
      console.log("Data has arrived:", ViewDATA);
    }
  }, [ViewDATA]);

  // Check if data exists and is an array
  const orderData = ViewDATA?.data?.[0] || {};
  const additionalInfo = ViewDATA?.data?.[1] || {};

  // Sample styles
  const styles = StyleSheet.create({
    page: { padding: 20 },
    header: { fontSize: 18, textAlign: "center", marginBottom: 10 },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 5,
    },
    columnLeft: {
      width: "70%",
      fontSize: 10,
      lineHeight: 1.3,
      fontWeight: "bold",
    },
    columnRight: {
      width: "30%",
      fontSize: 10,
      lineHeight: 1.3,
      textAlign: "right",
    },

    table: {
      marginTop: 10,
      borderWidth: 1,
      borderColor: "grey",
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
    },
    tableCol: {
      borderRightWidth: 1,
      borderRightColor: "#000",
      justifyContent: "center",
      padding: 5,
    },
    tableCell: { fontSize: 10, textAlign: "center" },
    footer: { fontSize: 10, marginTop: 10 },
    footerNote: { fontSize: 10, textAlign: "center", marginTop: 5 },
    declaration: {
      fontSize: 10,
      marginTop: 20,
      textAlign: "center",
      fontStyle: "italic",
    },
  });

  // PDF Document Component

  const InvoiceDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header Section */}
          <Text style={styles.header}>Tax Invoice</Text>
          <View style={styles.row}>
            <View style={styles.columnLeft}>
              <Text style={{ fontWeight: "bold" }}>
                {orderData.stockistname || ""}
              </Text>
              <Text>---</Text>
              <Text>FSSAI No: {orderData.p_fssai_no || ""}</Text>
              <Text>GSTIN/UIN: {orderData.p_gst_no || ""}</Text>
              <Text>State Name: ..., Code: ...</Text>
              <Text>Email: ---</Text>
            </View>
            <View style={styles.columnRight}>
              <Text style={{ fontWeight: "bold" }}>
                Invoice No.: {additionalInfo.invoice_number}
              </Text>
              <Text>Delivery Note:</Text>
              <Text>Dispatch Doc No:</Text>
              <Text>Dispatched through:</Text>
            </View>
            <View style={styles.columnRight}>
              <Text>Dated: {additionalInfo.created_at}</Text>
              <Text>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
              {/* Four non-breaking spaces */}
              <Text>Delivery Note Date:</Text>
              <Text>Destination:</Text>
            </View>
          </View>

          {/* Buyer Section */}
          <View style={[styles.columnLeft, { marginBottom: 10 }]}>
            <Text style={{ fontWeight: "bold" }}>Buyer (Bill to):</Text>
            <Text> {orderData.retailername || ""}</Text>

            <Text>FSSAI No: {orderData.r_fssai_no || ""}</Text>
            <Text>GSTIN/UIN: {orderData.r_gst_no || ""}</Text>
            <Text>State Name: ..., Code: ...</Text>
            <Text>Contact: ...</Text>
          </View>

          {/* Table Header */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>S No.</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>Description of Goods</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>HSN/SAC</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>GST</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>Quantity</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>Per</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>Rate</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>Rate (Incl. of Tax)</Text>
              </View>

              {/* New Column */}

              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>Disc %</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>Amount</Text>
              </View>
            </View>
            {/* Table Rows */}
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>1</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                {/* {ViewDATA[0]?.itemid || ""} */}
                <Text style={styles.tableCell}>Sahyadri Tea</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>77019001</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>12%</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              >
                <Text style={styles.tableCell}>4</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>KG</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>223.21</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>250.00</Text>
              </View>

              {/* New Column Data */}

              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>0%</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>892.84</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>2</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>Parivar Family</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>800190</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>5%</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              >
                <Text style={styles.tableCell}>6</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>KG</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>466.67</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>490.00</Text>
              </View>

              {/* New Column Data */}

              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>0%</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>2,800.02</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>CGST</Text>
                <Text style={styles.tableCell}>SGST</Text>
                <Text style={styles.tableCell}>Less: Round Off </Text>
                <Text style={styles.tableCell}>Less: Discount </Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}></View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              ></View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              {/* New Column Data */}
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>3,692.86</Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>123.57</Text>
                <Text style={styles.tableCell}>123.57</Text>
                <Text style={styles.tableCell}>(-) 00.00</Text>
                <Text style={styles.tableCell}>(-) 00.00</Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>3,940.00</Text>
              </View>
            </View>
          </View>

          {/* Footer Section */}
          <Text style={styles.footer}>
            Amount Chargeable (in words): INR Three Thousand Nine Hundred Forty
            Only.
          </Text>

          {/* Declaration */}
          <Text style={styles.declaration}>
            We declare that this invoice shows the actual price of the goods
            described and that all particulars are true and correct.
          </Text>
          <Text style={styles.footerNote}>
            This is a Computer Generated Invoice
          </Text>
        </Page>
      </Document>
    );
  };

  // For Excel export code

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    // Initialize sheet data with blank rows and columns for precise positioning
    const sheetData = Array(50)
      .fill([])
      .map(() => Array(15).fill(""));

    // Tax Invoice Heading
    sheetData[0][0] = "TAX INVOICE"; // Add the "Tax Invoice" heading in the first row

    // Header Information
    sheetData[1][0] = "Shree Balaji Agency";
    sheetData[2][0] = "Navin Kumbhar Vasahat, Opp Church, Gadhinlaj";
    sheetData[3][0] = "FSSAI No: 11517034000914";
    sheetData[4][0] = "GSTIN/UIN: 27CEYPK4974G1ZV";
    sheetData[5][0] = "State Name: Maharashtra, Code: 27";
    sheetData[6][0] = "Email: sreebalajiagency6453@gmail.com";

    // Invoice Metadata
    sheetData[1][7] = "Invoice No.2031";
    sheetData[1][8] = "Dated: 11-Sep-24";

    sheetData[2][7] = "Delivery Note";
    sheetData[2][8] = "--";
    sheetData[3][7] = "Dispatch Doc No.";
    sheetData[3][8] = "Delivery Note Date";

    sheetData[4][7] = "Dispatched through";
    sheetData[4][8] = "Destination";

    // Buyer Information
    sheetData[8][0] = "Buyer (Bill to)";
    sheetData[9][0] = "New Apala Bazar Gadhinlaj";
    sheetData[10][0] = "Aajara Road, Gadhinlaj";
    sheetData[11][0] = "GSTIN/UIN: 27CUQP3P327A1ZK";
    sheetData[12][0] = "State Name: Maharashtra, Code: 27";
    sheetData[13][0] = "Contact: 7385307133, 8805363842";

    // Itemized Table Header
    const itemHeaders = [
      "Sr",
      "Description of Goods",
      "HSN/SAC",
      "GST Rate",
      "Quantity Shipped",
      "Quantity Billed",
      "Rate (per)",
      "Discount (%)",
      "Amount",
    ];
    sheetData[15] = itemHeaders;

    // Itemized Rows (Add based on invoice data)
    const items = [
      [
        1,
        "Mango Pickle 5kg Jar Rs.380",
        "20019000",
        "12%",
        "4 nos",
        "4 nos",
        "250.00",
        "",
        "892.84",
      ],
      [
        2,
        "Parivar Family 1kg Rs 540",
        "090230",
        "5%",
        "6 KG",
        "6 KG",
        "490.00",
        "",
        "2,800.02",
      ],
      [
        3,
        "Parivar Tea 500gm Rs285",
        "090230",
        "5%",
        "2 KG",
        "2 KG",
        "520.00",
        "",
        "990.48",
      ],
      [
        4,
        "Sahyadri Elaichi 100gms Rs.38",
        "090230",
        "5%",
        "5 KG",
        "5 KG",
        "300.00",
        "",
        "1,428.55",
      ],
      [
        5,
        "Sahyadri Elaichi 1kg Rs 360",
        "090230",
        "5%",
        "24 KG",
        "24 KG",
        "300.00",
        "",
        "6,857.04",
      ],
      [
        6,
        "Sahyadri Elaichi 250gm Rs 95",
        "090230",
        "5%",
        "48 KG",
        "48 KG",
        "305.00",
        "",
        "13,943.04",
      ],
    ];

    items.forEach((item, index) => {
      sheetData[16 + index] = item;
    });

    // Totals Section
    sheetData[22][7] = "Total";
    sheetData[22][8] = "₹ 28,120.00";

    // Footer and Bank Details
    sheetData[24][0] = "Amount Chargeable (in words)";
    sheetData[25][0] = "INR Twenty Eight Thousand One Hundred Twenty Only";

    sheetData[27][0] = "HSN/SAC";
    sheetData[27][1] = "Taxable Value";
    sheetData[27][2] = "Central Tax Rate";
    sheetData[27][3] = "Central Tax Amount";
    sheetData[27][4] = "State Tax Rate";
    sheetData[27][5] = "State Tax Amount";
    sheetData[27][6] = "Total Tax Amount";

    sheetData[28] = [
      "20019000",
      "892.84",
      "6%",
      "53.57",
      "6%",
      "53.57",
      "107.14",
    ];
    sheetData[29] = [
      "090230",
      "26,019.13",
      "2.5%",
      "650.48",
      "2.5%",
      "650.48",
      "1,300.96",
    ];
    sheetData[30][1] = "Total";
    sheetData[30][2] = "26,911.97";
    sheetData[30][3] = "704.05";
    sheetData[30][5] = "704.05";
    sheetData[30][6] = "1,408.10";

    // Footer and Bank Details
    sheetData[32][0] = "Amount Chargeable (in words)";
    sheetData[33][0] = "INR Twenty Eight Thousand One Hundred Twenty Only";

    sheetData[34][0] = "Company's Bank Details";
    sheetData[35][0] = "A/c Holder's Name: Shree Balaji Agency";
    sheetData[36][0] = "Bank Name: SHRI WARANA SAH BANK_114";
    sheetData[37][0] = "A/c No: 397004086000114";
    sheetData[38][0] = "Branch & IFS Code: HDFCCOSWSBL";

    // Declaration
    sheetData[39][0] = "Declaration";
    sheetData[40][0] =
      "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.";

    // Merging Cells
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // "Tax Invoice" across 5 columns
      { s: { r: 7, c: 0 }, e: { r: 7, c: 4 } }, // "Buyer (Bill to)" across 5 columns
      { s: { r: 23, c: 0 }, e: { r: 23, c: 4 } }, // "Amount Chargeable (in words)"
      { s: { r: 31, c: 0 }, e: { r: 31, c: 4 } }, // "Company's Bank Details"
      { s: { r: 37, c: 0 }, e: { r: 37, c: 4 } }, // "Declaration"
      // Add more merges as needed to replicate layout
    ];

    // Create worksheet with merged cells
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    worksheet["!merges"] = merges;

    // Apply bold font to "TAX INVOICE"
    worksheet["A1"].s = {
      font: { bold: true },
    };

    // Export
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
    XLSX.writeFile(workbook, "Excel_Invoice.xlsx");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      fullScreen={fullscreen}
    >
      <DialogTitle
        id="customized-dialog-title"
        // sx={{ bgcolor: "#5052ba", color: "white" }}
        sx={{ bgcolor: MyBgColor ? MyBgColor : "#5052ba", color: "white" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {title}
          </Grid>
          {/* For PDF_Invoice code start */}

          <Grid item xs={1}>
            {PDFData ? (
              <PDFDownloadLink
                document={<InvoiceDocument />}
                fileName="PDF_invoice.pdf"
              >
                {({ loading }) => (
                  <Button sx={{ color: "white", border: "1px solid #c7b4b4" }}>
                    {loading ? "Loading document..." : PDFData}
                  </Button>
                )}
              </PDFDownloadLink>
            ) : null}
          </Grid>
          {/* For PDF_Invoice code end */}

          {/* For Excel Export code start */}
          <Grid item xs={2}>
            {ExcelData ? (
              <Button
                sx={{ color: "white", border: "1px solid #c7b4b4" }}
                onClick={() => handleExport()}
              >
                {ExcelData}
              </Button>
            ) : null}
          </Grid>
          {/* For Excel Export code end */}
        </Grid>
        {/* Close Function code start */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#ff0000fa",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Close Function code end */}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ paddingTop: "2px" }}>{children}</DialogContent>
      <Divider />
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    PropTypes.bool,
  ]),
};

export default CustomDialog;
