const API_ENDPOINTS = {
  // In Dashboard
  Order_counts: `/counts/orders_counts`,
  Filter_api_salesGroup: `/api/filter_api_salesgroup/`,
  // In All order
  Api_order_taking: `/api/orderTaking/all-orders-items/order-by-dashboard`,
  Api_all_order_view: `/api/all_order_view`,
  Invoice_generator: `/api/invoice_genrator_api`,
  Delete_orders: `/api/delete-order-dashboard`,
  Edit_orders: `/api/items-cd-edit`,

  GET_USERS: "/users",
  GET_PRODUCTS: "/products",
  GET_ORDERS: "/orders",
  CREATE_USER: "/users",
  UPDATE_USER: (id) => `/users/${id}`,
  DELETE_USER: (id) => `/users/${id}`,
};

export default API_ENDPOINTS;
