import React, { useState, useEffect, useCallback } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DeleteForeverOutlined } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  TablePagination,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  Autocomplete,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import API_ENDPOINTS from "../../../../apiServices/apiEndpoints";
import SendIcon from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import { green } from "@mui/material/colors";
import toast, { Toaster } from "react-hot-toast";

import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedFilterAllOrder,
  updateCustomDateRangeAllOrder,
} from "../../../../feature/dateFilter/dateFilterSlice";

import {
  getRequestInstanceOne,
  postRequest,
  putRequest,
} from "../../../../apiServices/apiCall";
import { CustomLoader } from "../../../../components/CustomLoader/CustomLoader";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog";

const StyledTableCell = styled(TableCell)(({ theme, isHeader }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: isHeader ? "center" : "left",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
  padding: "8px 12px",
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));

export default function AllOrderTable({ SettotalRowCount2 }) {
  const currentDate = new Date().toISOString().split("T")[0];
  const [dateFilter, setDateFilter] = useState(currentDate);
  const [rows, setRows] = useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewDialogImg, setOpenViewDialogImg] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewData, setViewData] = useState([]);
  const [viewData_PDF, setViewData_PDF] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermValue, setSearchTermValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null); // New state for debounce timer
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState("");
  const [discount, setDiscount] = useState("");
  const [Discount_free_pkts, setDiscount_free_pkts] = useState("");
  const [discount_CD, setDiscount_CD] = useState("");
  const [discounted, setDiscounted] = useState("");

  const dispatch = useDispatch();
  const selectedFilter = useSelector(
    (state) => state.allOrderDateFilter.selectedFilter
  );
  const customDateRange = useSelector(
    (state) => state.allOrderDateFilter.customDateRange
  );

  // total count sending to OrderDetails compo. start
  SettotalRowCount2(totalRowCount);
  // total count sending to OrderDetails compo. end

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOpenDialog(false);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rowsPerPage changes
    setOpenDialog(false);
  };

  const getOrderList = useCallback(async () => {
    setIsLoading(true);
    const params = new URLSearchParams({
      page: page + 1,
      per_page: rowsPerPage,
      start_date: customDateRange.start_date,
      end_date: customDateRange.end_date,
      ...(localStorage.getItem("user") !== "admin"
        ? { user: localStorage.getItem("user") }
        : {}),
      ...(searchTermValue ? { query_string: searchTermValue } : {}),
    });

    try {
      const url = API_ENDPOINTS.Api_order_taking;
      const result = await getRequestInstanceOne(url, params);
      const data = result.data[0];

      const restructureData = data.results?.map((items) => ({
        id: items?.id,
        items: items?.items,
        orderId: items?.orderId,
        order_by: items?.order_by?.name,
        payerId: items?.r_info?.payerId,
        s_name: items?.r_info?.s_name,
        customer: items?.r_info?.r_name,
        area: items?.r_info?.area,
        group: items?.r_info?.salesgroup,
        order_at: items?.order_at,
        status: items?.status,
        qty: items?.items
          ?.reduce((total, item) => total + Number(item?.qty), 0)
          .toString(),
        location: items?.location,
        image: items?.image,
      }));

      setRows(restructureData);
      setTotalRowCount(
        searchTermValue
          ? result.data[0]?.count[0].total
          : result.data[0]?.count[0].total
      );
    } catch (error) {
      console.error(error);
      setRows([]);
    } finally {
      setIsLoading(false);
    }
  }, [
    dateFilter,
    page,
    rowsPerPage,
    customDateRange,
    searchTermValue,
    selectedFilter,
  ]);

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    dispatch(updateSelectedFilterAllOrder(`${start_date} to ${end_date}`));
    setOpenDialog(false);
  };

  const handleFilterSelect = (filter) => {
    dispatch(updateSelectedFilterAllOrder(filter));
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
    }
    dispatch(updateCustomDateRangeAllOrder({ start_date, end_date }));
  };

  useEffect(() => {
    getOrderList(); // Ensure it calls the updated customDateRange values
  }, [page, rowsPerPage, searchTermValue, selectedFilter]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);

    // Clear previous debounce timer
    if (debounceTimer) clearTimeout(debounceTimer);

    // Set new debounce timer
    const newTimer = setTimeout(() => {
      if (event.target.value.trim() !== "") {
        setSearchTermValue(event.target.value); // Trigger search when user stops typing
        setPage(0);
      } else {
        setSearchTermValue(""); // Trigger clear when input is empty
        setPage(0);
      }
    }, 2000); // 2-second delay

    setDebounceTimer(newTimer); // Save the timer to the state
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    dispatch(updateSelectedFilterAllOrder(""));
  };

  const handleDateChange = (field) => (event) => {
    dispatch(
      updateCustomDateRangeAllOrder({
        ...customDateRange,
        [field]: event.target.value,
      })
    );
  };

  // const handleSearchClick = () => {
  //   setSearchTermValue(searchTerm);
  //   setPage(0);
  // };

  const handleClearSearch = () => {
    setSearchTermValue("");
    setSearchTerm("");
    setPage(0);
    getOrderList();
  };

  // Making text ellipsis... start

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Making text ellipsis... end

  const handleopenView_img1 = (params) => {
    setImage(params.image);
    setOpenViewDialogImg(true);
  };

  const getOrderListViewItem1 = async (orderid) => {
    setIsLoading(true);
    try {
      const url = API_ENDPOINTS.Api_all_order_view;
      const params = {
        orderId: orderid,
        start_date: customDateRange.start_date,
        end_date: customDateRange.end_date,
      };
      const result = await getRequestInstanceOne(url, params);
      const allOrderViewData = result.data;

      // console.log(allOrderViewData[0]._id);
      setDiscounted(allOrderViewData[0].discount_amount || 0);

      if (allOrderViewData) {
        setOpenViewDialog(true);
        setViewData(allOrderViewData);
      } else {
        setOpenViewDialog(true);
        setViewData([]);
      }
    } catch (error) {
      console.error(error);
      setViewData([]);
    } finally {
      setIsLoading(false);
    }
  };
  // Get api for PDF
  const fetchAllOrderView = async (orderid) => {
    const params = new URLSearchParams({
      orderId: orderid,
      invoice_number: orderid,
    });

    const url = API_ENDPOINTS.Api_all_order_view;

    try {
      const response = await getRequestInstanceOne(url, params);

      if (!response.ok) {
        console.warn(
          `Warning: API responded with status ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      console.log("Fetched Data:", data);

      // Handle your data (e.g., set it in state)
      setViewData_PDF(data); // Uncomment if you have a state setter
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // Post api for PDF
  const fetchInvoiceGenerator = async (orderId) => {
    try {
      // Define API endpoint
      const endpoint = API_ENDPOINTS.Invoice_generator;
      const body = { orderId };
      // Use reusable postRequest function
      const result = await postRequest(endpoint, body);

      console.log("Invoice generated:", result);

      // Fetch all orders after invoice generation
      fetchAllOrderView(orderId);

      return result;
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  const handleopenView1 = (params) => {
    // console.log("before clicked", params);
    const orderid = params?.orderId;
    const cash_disc = params?.cash_disc;
    if (orderid) {
      getOrderListViewItem1(orderid);
      setTimeout(() => {
        fetchInvoiceGenerator(orderid);
      }, 2000);
    }
    // console.log("after clicked", orderid);
  };

  let totalQty = 0;
  let totalAmount = 0;
  let totalTPR = 0;
  let totalTax = 0;
  let totalFreePkts = 0;
  let totalCD = 0;

  // Calculate totals
  if (Array.isArray(viewData) && viewData.length > 0) {
    viewData.forEach((view) => {
      // Check if 'view' has an 'itemid' array, which we need for further processing
      if (
        view?.itemid &&
        Array.isArray(view.itemid) &&
        view.itemid.length > 0
      ) {
        view.itemid.forEach((_, idx) => {
          const qty = parseFloat(view.quantity?.[idx]) || 0;
          const price = parseFloat(view.price?.[idx]) || 0;
          const total = qty * price;
          const tpr = parseFloat(view.discount?.[idx]) || 0;
          const tax = parseFloat(view.tax?.[idx]) || 0;
          const freePkts = parseFloat(view.free?.[idx]) || 0;
          const cd = parseFloat(view.cash_disc?.[idx]) || 0;

          totalQty += qty;
          totalAmount += total;
          totalTPR += tpr;
          totalTax += tax;
          totalFreePkts += freePkts;
          totalCD += cd;
        });
      }
    });
  }

  // function for delete data
  const DeleteData = async (row) => {
    const { orderId } = row;
    const data = false;

    if (!window.confirm("Are you sure you want to delete this item?")) {
      toast.error("Delete action was canceled.");
      return;
    }

    setIsLoading(true);

    try {
      const endpoint = API_ENDPOINTS.Delete_orders;
      const body = { orderId, data };

      console.log("Request body:", body);

      await postRequest(endpoint, body);

      getOrderList(customDateRange.start_date, customDateRange.end_date);
      toast.success("Successfully Deleted!");
    } catch (error) {
      console.error(
        "Failed to delete data:",
        error.response?.data || error.message
      );
      toast.error("Failed to delete the data");
    } finally {
      setIsLoading(false);
    }
  };

  // funtion for adding discount
  const updateItemDiscount = async (OrderID, discount_amount) => {
    const endpoint = API_ENDPOINTS.Edit_orders;

    try {
      const body = { orderId: OrderID, discount_amount };
      console.log("Request body:", body);

      const response = await putRequest(endpoint, body);
      console.log("Response:", response);
    } catch (error) {
      console.error(
        "Error updating item discount:",
        error.response?.data || error.message
      );
    } finally {
      setDiscount("");
      setDiscounted(discount);
    }
  };

  // USer input value getting
  const UserInputChange = (event) => {
    setDiscount(event.target.value);
  };
  const UserInputChange1 = (event) => {
    setDiscount_free_pkts(event.target.value);
  };
  const UserInputChange2 = (event) => {
    setDiscount_CD(event.target.value);
  };

  return (
    <>
      <Toaster />
      <Box sx={{ width: "100%" }}>
        <CustomLoader open={isLoading} />
        <Paper sx={{ width: "100%", p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip
                  title={`${customDateRange.start_date} ${customDateRange.end_date}`}
                  placement="top"
                  arrow
                >
                  <Autocomplete
                    disablePortal
                    disableClearable
                    size="small"
                    sx={{ bgcolor: "#fff", width: 200 }}
                    id="combo-box-demo"
                    value={selectedFilter}
                    options={[
                      "Today",
                      "Weekly",
                      "Monthly",
                      "Yearly",
                      "Custom Range",
                    ]}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleFilterSelect(value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Date Filter" />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <TextField
                  size="small"
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchTerm && (
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )}
                        {/* <IconButton onClick={handleSearchClick}>
                          <SearchIcon />
                        </IconButton> */}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>
          </Box>
          <TableWrapper>
            <TableContainerWrapper component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      OrderID
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      OrderBy
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      PayerId
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Stockist
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Beat Name
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Customer
                    </StyledTableCell>
                    {/* <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Area
                    </StyledTableCell> */}
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Group
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Date & Time
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Order_Status
                    </StyledTableCell>
                    <StyledTableCell
                      isHeader
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell isHeader>Location</StyledTableCell>
                    <StyledTableCell isHeader>Image</StyledTableCell>
                    <StyledTableCell isHeader>Action</StyledTableCell>
                    {localStorage.getItem("user") === "admin" && (
                      <StyledTableCell isHeader>Delete</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <Tooltip title={row.orderId || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.orderId || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.order_by || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.order_by || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                        }}
                      >
                        {row.payerId || "-"}
                      </TableCell>
                      <Tooltip title={row.s_name || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.s_name || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.location.beat_name || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.location.beat_name || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.customer || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.customer || "-", 15)}
                        </TableCell>
                      </Tooltip>

                      {/* <Tooltip title={row.area || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.area || "-", 15)}
                        </TableCell>
                      </Tooltip> */}

                      <Tooltip title={row.group || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.group || "-", 15)}
                        </TableCell>
                      </Tooltip>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.order_at || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {/* {row.location.no_order_reason || "-"} */}

                        {row.qty
                          ? row.qty == 0
                            ? row.location.no_order_reason
                            : row.status
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.qty || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.location &&
                        row.location.lattitude &&
                        row.location.logitude ? (
                          <a
                            href={`https://www.google.com/maps?q=${row.location.logitude},${row.location.lattitude}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton>
                              <LocationOnIcon />
                            </IconButton>
                          </a>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                          cursor: "not-allowed",
                        }}
                      >
                        <IconButton
                          disabled={
                            row.location.no_order_reason == "Store Closed"
                              ? false
                              : true
                          }
                        >
                          <ImageIcon
                            onClick={() => handleopenView_img1(row)}
                            sx={{
                              color:
                                row.location.no_order_reason == "Store Closed"
                                  ? green["600"]
                                  : green["100"],
                            }}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          background: "white",
                          padding: "0 5px",
                        }}
                      >
                        <Button
                          disabled={
                            row.location.no_order_reason === "Store Closed" ||
                            row.location.no_order_reason ===
                              "Stock Available" ||
                            (row.location.no_order_reason === "ONA" &&
                              row.qty === 0)
                              ? true
                              : false
                          }
                          variant="outlined"
                          size="small"
                          color="inherit"
                          onClick={() => handleopenView1(row)}
                        >
                          View
                        </Button>
                      </TableCell>

                      {localStorage.getItem("user")?.trim() === "admin" && (
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            textAlign: "center",
                          }}
                        >
                          <IconButton>
                            <DeleteForeverOutlined
                              sx={{ color: "red" }}
                              onClick={() => DeleteData(row)}
                            />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainerWrapper>
          </TableWrapper>
          <TablePagination
            component="div"
            count={totalRowCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Select Date Range"
        fullscreen={false}
        maxWidth="sm"
      >
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={customDateRange.start_date}
          onChange={handleDateChange("start_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={customDateRange.end_date}
          onChange={handleDateChange("end_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDialogClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCustomRangeSubmit}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>

      <CustomDialog
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        title={`View Quantity`}
        PDFData={`PDF`}
        ExcelData={`Excel`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
        MyBgColor="#727297"
        // ViewDATA={viewData?.[0]?._id}
        ViewDATA={viewData_PDF || {}}
        Discounted_Amount={discounted}
      >
        {viewData.map((val) => {
          return (
            <>
              {/* <Typography sx={{ color: "maroon" }}>
                OrderId:<small style={{ color: "darkgreen" }}>{val?._id}</small>{" "}
              </Typography>
              <Typography sx={{ color: "maroon" }}>
                Date:
                <small style={{ color: "darkgreen" }}>{val?.datetime}</small>
              </Typography> */}

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={4} md={4}>
                    <Typography sx={{ color: "maroon" }}>
                      OrderId:
                      <small style={{ color: "darkgreen" }}>{val?._id}</small>
                    </Typography>
                    <Typography sx={{ color: "maroon" }}>
                      Date:
                      <small style={{ color: "darkgreen" }}>
                        {val?.datetime}
                      </small>
                    </Typography>
                  </Grid>

                  <Grid item xs={2} md={2}>
                    <TextField
                      id="standard-basic"
                      label="Free Pkts Discount"
                      color="info"
                      variant="standard"
                      value={Discount_free_pkts}
                      onChange={UserInputChange1}
                    />
                  </Grid>

                  <Grid item xs={2} md={2}>
                    <TextField
                      id="standard-basic"
                      label="CD Discount"
                      color="success"
                      variant="standard"
                      value={discount_CD}
                      onChange={UserInputChange2}
                    />
                  </Grid>

                  <Grid item xs={2} md={2}>
                    <TextField
                      id="standard-basic"
                      label="Add Discount"
                      color="warning"
                      variant="standard"
                      value={discount}
                      onChange={UserInputChange}
                    />
                  </Grid>

                  <Grid item xs={2} md={2}>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      endIcon={<SendIcon />}
                      onClick={() => updateItemDiscount(val?._id, discount)} // Wrap in arrow function
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
        <TableWrapper>
          <TableContainerWrapper component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    SKU
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Rate
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Qty
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Total
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    TPR
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Tax
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    Free Pkts
                  </StyledTableCell>
                  <StyledTableCell
                    isHeader
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "#835454",
                    }}
                  >
                    CD
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData?.length > 0
                  ? viewData.map((view, index) =>
                      // Check if 'itemid' is present and is an array
                      view.itemid?.map((_, idx) => {
                        const qty = parseFloat(view?.quantity[idx]) || 0;
                        const price = parseFloat(view?.price[idx]) || 0;
                        const total = (qty * price).toFixed(2);
                        const tpr =
                          parseFloat(view?.discount[idx])?.toFixed(2) || 0;
                        const tax = parseFloat(view?.tax[idx])?.toFixed(2) || 0;
                        const freePkts =
                          parseFloat(view?.free[idx])?.toFixed(2) || 0;
                        const cd =
                          parseFloat(view?.cash_disc[idx])?.toFixed(2) || 0;

                        return (
                          <StyledTableRow key={`${index}-${idx}`}>
                            <TableCell align="center">
                              {view?.itemid[idx]}
                            </TableCell>
                            <TableCell align="center">
                              {price.toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              {qty.toFixed(2)}
                            </TableCell>
                            <TableCell align="center">{total}</TableCell>
                            <TableCell align="center">{tpr}</TableCell>
                            <TableCell align="center">
                              {(price * (tax / 100)).toFixed(2)}
                            </TableCell>
                            <TableCell align="center">{freePkts}</TableCell>
                            <TableCell align="center">{cd}</TableCell>
                          </StyledTableRow>
                        );
                      })
                    )
                  : null}

                {/* Summary Row */}
                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalQty.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalAmount.toFixed(2)}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalTPR.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalTax.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalFreePkts.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#e0e0e0",
                      color: "#936843",
                    }}
                  >
                    {totalCD.toFixed(2)}
                  </TableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#ffe4c4",
                    }}
                  >
                    Discount - CD Discount:
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  >
                    {discounted - discount_CD}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#800000",
                    }}
                  >
                    Discount_free_pkts:
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  >
                    {Discount_free_pkts}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#65977b7a",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#000080",
                    }}
                  >
                    Total After Discount
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#5d7172",
                    }}
                  >
                    {discounted
                      ? Number(
                          (
                            Number(totalAmount.toFixed(2)) -
                            discounted -
                            discount_CD
                          ).toFixed(2)
                        )
                      : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#d6ebff",
                      color: "#5d7172",
                    }}
                  ></TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainerWrapper>
        </TableWrapper>
      </CustomDialog>

      {/* custome dialog start*/}
      <CustomDialog
        open={openViewDialogImg}
        onClose={() => {
          setOpenViewDialogImg(false);
        }}
        title={`View Image`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <CustomLoader open={isLoading} />

          {/* Conditional rendering based on image availability */}
          {image ? (
            <img src={image} alt="image" width={850} height={500} />
          ) : (
            <>
              <h2 style={{ color: "#ff0000a1" }}>No image found</h2>
            </>
          )}
        </div>
      </CustomDialog>

      {/* custome dialog end*/}
    </>
  );
}
