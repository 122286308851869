import React, { useEffect, useState } from "react";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import {
  getRequestForDownload,
  getRequestInstanceOne,
} from "../../../apiServices/apiCall";

const ExportExcel = ({ setOpenDialog }) => {
  const [filterValue1, setFilterValue1] = useState("");
  const [filterValue2, setFilterValue2] = useState("");
  const [filterValue3, setFilterValue3] = useState(""); // Added state for third filter value
  const [options, setOptions] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [workingDays, setWorkingDays] = useState(null);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setCustomDateRange({ start_date: "", end_date: "" });
  }, []);

  // const fetchData = async (selectedValue) => {
  //   setIsLoading(true);
  //   try {
  //     const params = new URLSearchParams();
  //     params.append("key",filterValue1);
  //     const response = await getRequestInstanceOne(
  //       `/api/get_all_list/?${params.toString()}&start_date=${customDateRange.start_date}&end_date=${customDateRange.end_date}`
  //     );

  //   // Sorting options alphabetically by `name`
  //   const sortedData = response.data.sort((a, b) => {
  //     if (a.name && b.name) {
  //       return a.name.localeCompare(b.name);
  //     }
  //     return 0;
  //   });

  //     setOptions(sortedData);
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchData = async (selectedValue) => {
    setIsLoading(true);
    try {
      let params = new URLSearchParams();

      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      } else {
        params.append("key", selectedValue);
      }

      const response = await getRequestInstanceOne(
        `/api/get_all_list/?${params.toString()}&start_date=${
          customDateRange.start_date
        }&end_date=${customDateRange.end_date}`
      );

      console.log("API Response:", response.data);

      const sortedData = response.data.sort((a, b) => {
        const nameA = (a.name || a._id || "").toLowerCase();
        const nameB = (b.name || b._id || "").toLowerCase();
        // Ensure "ALL" is always on top
        if (nameA === "all") return -1; // a comes before b
        if (nameB === "all") return 1; // b comes before a
        return nameA.localeCompare(nameB);
      });

      setOptions([...sortedData]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filterValue1);
  }, [filterValue1]);

  const handleDateChange = (field) => (event) => {
    setCustomDateRange({ ...customDateRange, [field]: event.target.value });
  };

  const getWorkingDays = async (id) => {
    const { start_date, end_date } = customDateRange;

    // Early return if start_date or end_date is missing
    if (!start_date || !end_date) {
      console.error("Start date or end date is missing");
      return;
    }

    const params = new URLSearchParams({
      from_date: start_date,
      to_date: end_date,
      empId: id,
    });

    const url = `http://143.244.133.43:5000/api/expense/working_days_range?${params.toString()}`;

    try {
      const response = await fetch(url);

      // Check if response is successful
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();

      // Validate if result and id exist
      const fetchedWorkingDays = data.result?.[id]?.working_days;

      if (fetchedWorkingDays !== undefined) {
        console.log("Working days fetched:", fetchedWorkingDays);
        setWorkingDays(fetchedWorkingDays); // Update the state
      } else {
        console.error(`No working days found for empId: ${id}`);
      }
    } catch (error) {
      console.error("Error fetching working days:", error.message);
    }
  };

  const handleSubmit = async () => {
    // await getWorkingDays(id); // Get working days before exporting Excel
    if (workingDays !== null) {
      exportExcel(workingDays); // Pass workingDays to exportExcel
    }
  };

  const exportExcel = async (workingDays) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      params.append(filterValue1, filterValue2);
      params.append("type", filterValue3); // Assuming filterValue3 is properly set
      if (filterValue1 == "name") {
        params.append("working_days", workingDays);
      }

      const url = `/download-excel/${customDateRange.start_date}/${
        customDateRange.end_date
      }?${params.toString()}`;

      const result = await getRequestForDownload(url);
      const res = new Blob([result]);
      const downloadUrl = window.URL.createObjectURL(res);

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `Excel_${filterValue2}_${filterValue3}_${customDateRange.start_date}_${customDateRange.end_date}.xlsx`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterValue1Change = (event, newValue) => {
    setFilterValue1(newValue);
  };

  const handleFilterValue2Change = (event, newValue) => {
    if (newValue != null) {
      setFilterValue2(newValue.name || newValue._id || newValue.salesgroup);
    }
    // Update filterValue1 state
  };

  // Handle selection change for the third dropdown
  const handleFilterValue3Change = (event, newValue) => {
    setFilterValue3(newValue); // Update filterValue3 state
  };

  const isDateRangeSelected =
    customDateRange.start_date && customDateRange.end_date;

  const handleBothFunctions = (event, value) => {
    console.log("Selected value:", value);

    if (value) {
      handleFilterValue2Change(null, value); // Pass the selected value to update filterValue2
      getWorkingDays(value.empid); // Call the second function with id
    }
  };

  return (
    <>
      <CustomLoader open={isLoading} />

      <TextField
        margin="dense"
        label="Start Date"
        type="date"
        fullWidth
        value={customDateRange.start_date}
        onChange={handleDateChange("start_date")}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        margin="dense"
        label="End Date"
        type="date"
        fullWidth
        value={customDateRange.end_date}
        onChange={handleDateChange("end_date")}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <div style={{ display: "flex", marginBottom: "16px" }}>
        {localStorage.getItem("user")?.trim() === "admin" && (
          <Autocomplete
            disabled={!isDateRangeSelected}
            disableClearable
            size="small"
            id="combo-box-demo-1"
            options={["salesgroup", "payerId", "name"]}
            value={filterValue1}
            onChange={handleFilterValue1Change}
            getOptionLabel={(option) => option}
            sx={{ width: 430, marginRight: "16px", marginTop: "5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Group by"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        {localStorage.getItem("user")?.trim() != "admin" && (
          <Autocomplete
            disableClearable
            size="small"
            id="combo-box-demo-1"
            options={["name"]}
            value={filterValue1}
            onChange={handleFilterValue1Change}
            getOptionLabel={(option) => option}
            sx={{ width: 430, marginRight: "16px", marginTop: "5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Group by"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        {localStorage.getItem("user")?.trim() === "admin" && (
          <Autocomplete
            disabled={!isDateRangeSelected}
            disableClearable
            size="small"
            id="combo-box-demo-2"
            options={options}
            getOptionLabel={(option) => option._id || option.name}
            onChange={handleBothFunctions}
            sx={{ width: 430, marginTop: "5px", marginRight: "16px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        {localStorage.getItem("user")?.trim() != "admin" && (
          <Autocomplete
            disableClearable
            size="small"
            id="combo-box-demo-2"
            options={options}
            getOptionLabel={(option) => option._id || option.name}
            onChange={handleBothFunctions}
            sx={{ width: 430, marginTop: "5px", marginRight: "16px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        <Autocomplete
          disabled={!isDateRangeSelected}
          disableClearable
          size="small"
          id="combo-box-demo-3"
          options={["order", "no_order", "all_order", "cancel"]}
          getOptionLabel={(option) => option}
          onChange={handleFilterValue3Change}
          sx={{ width: 430, marginTop: "5px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select type"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </div>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setOpenDialog(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit || exportExcel}
          sx={{
            backgroundColor: "#5052ba",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#4042a3",
            },
            ml: 2,
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default ExportExcel;
